import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <section className="intro">
      <div className="intro-container">
        <h2>Contact</h2>
        <p>Interested in hiring me for <strong>event consulting</strong>, <strong>risk management consulting</strong>, <strong>fetish modeling</strong>, <strong>event emceeing</strong>, or <strong>an interview</strong> for a media outlet, podcast, or video series?</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <form
          name="contact"
          method="post"
          action="/contact-success"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <div className="form-field">
            <label htmlFor="name">First name</label>
            <input type="text" name="firstname" id="firstname-contact" />
          </div>
          <div className="form-field">
            <label htmlFor="name">Last name</label>
            <input type="text" name="lastname" id="lastname" />
          </div>
          <div className="form-field">
            <label htmlFor="email">E-mail</label>
            <input type="text" name="email" id="email" />
          </div>
          <div className="form-field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="6" />
          </div>
          <ul className="form-actions">
            <li>
              <input type="submit" value="Send message" className="form-actions-submit" />
            </li>
            <li>
              <input type="reset" value="Clear" />
            </li>
          </ul>
        </form>
        <Link to="/" className="call-to-action">Go back home</Link>
      </div>
    </section>
  </Layout>
)

export default ContactPage
